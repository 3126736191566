import React from 'react';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import BlogLayout from '../layout/blog';

import Fade from 'react-reveal/Fade';

// SEO imports
import SEO from '../components/seo';

const PostTemplate = ({ data }) => (
	<BlogLayout>
		<SEO
			title={data.wordpressPost.title}
			description={data.wordpressPost.excerpt}
			image={data.wordpressPost.acf.thumbnail.localFile.url}
		/>
		<div className="article">
			<Fade>
				<div className="article__intro">
					<div className="article__intro-image">
						<Image
							sizes={data.wordpressPost.acf.thumbnail.localFile.childImageSharp.sizes}
							alt={data.wordpressPost.title}
						/>
					</div>
					<div className="article__intro-text">
						<h1 className="shout">{data.wordpressPost.title}</h1>
						<p className="talk">Written by Ryan Foose and Seth Rose on {data.wordpressPost.date}</p>
					</div>
				</div>
				<hr />
				<div className="article__body">
					<div dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }} />
				</div>
			</Fade>
			<Fade>
				<div className="back-to-blog">
					<div className="back-to-blog__content">
						<h2 className="shout">Go back to blog</h2>
						<p className="talk">Return to the main blog page to browse more articles.</p>
						<Link className="btn btn__accent" to="/blog/">
							Back to blog
						</Link>
					</div>
				</div>
			</Fade>
		</div>
	</BlogLayout>
);
export default PostTemplate;
export const postQuery = graphql`
	query($id: Int!) {
		wordpressPost(wordpress_id: { eq: $id }) {
			title
			content
			excerpt
			date(formatString: "MMMM DD, YYYY")
			author {
				name
			}
			acf {
				thumbnail {
					localFile {
						childImageSharp {
							sizes(maxWidth: 1200) {
								...GatsbyImageSharpSizes
							}
						}
						url
					}
				}
			}
		}
	}
`;
